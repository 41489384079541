import React, { useState, useContext, useEffect} from "react";
import LessonContext from "../../../context/LessonContext";
import Spinner from "../../../components/General/Spinner";
import Lesson from "../Lesson";
import useLesson from "../../../hook/useLesson";
import {  useHistory, useParams} from "react-router-dom";

const Choice = () => {
  const {languageId, topicId} = useParams()
  const ctx = useContext(LessonContext);
  const { getLessons, lessons} = useLesson()

  useEffect(() => {
    getLessons(topicId, languageId)
  },[languageId])



  return (
    <div className="flex text-white flex-col h-screen items-center p-2">
      {ctx.state.loading && <Spinner />}

      <div className="flex flex-wrap pb-32 gap-2 place-content-center">
        {lessons.map((e, i) => {
          return (
            <div key={i}>
              <Lesson lessons={e}/>
            </div>
          );
        })}
        
      </div>
    </div>
  );
};
export default Choice;

