import React ,{useContext, useState, useEffect} from "react";
import ToolSidebar from "../../components/ToolSidebar";
import UserContext from "../../context/UserContext";
import { IoIosArrowBack} from "react-icons/io";
import {  useHistory ,useParams} from "react-router-dom";
import useLesson from "../../hook/useLesson";

const LessonLevel = () => {
    const {languageId} = useParams()
    const ctx = useContext(UserContext)  
    const history = useHistory();
    const {levelId, getLevelId} = useLesson()

    useEffect(() => {
        getLevelId(languageId)
      },[languageId])

    const selectLevel = (level) => {
        if(level != ''){
            history.push(`/number/${languageId}/${level}`)
        }
    };

    return (
        <div className="relative flex flex-col items-center h-screen pt-10 px-6 md:p-0">
            <div className="z-30">
                <ToolSidebar />
            </div>
            
            <div className="relative text-gray-400 border rounded-2xl px-4 py-2 border-gray-400 md:mt-28 bg-baseBlack/10 text-xl flex justify-between w-[310px] m-auto">
                <p>{ctx?.currentUser?.name}</p>
                <p>{ctx?.currentUser?.amount}₮</p>
                <p>{ctx?.currentUser?.coins}coin</p>
                <IoIosArrowBack 
                    className="text-white absolute -left-6"
                    size={20} onClick={() => history.push("/lesson")}
                />
            </div>

            <div className=" mt-10 flex flex-wrap gap-2 place-content-center w-full sm:w-[80%] xl:w-[60%]">
                {levelId.map((e, i) => {
                return (
                    <div
                    className={`w-[40%] sm:w-[30%] md:w-[20%] aspect-square hover:bg-baseBlue1 hover:text-white md:text-2xl bg-white font-bold text-baseBlack p-4 flex items-center justify-center rounded-2xl` }
                
                    key={i}
                    onClick={() => selectLevel(e?.id)}
                    >
                    <p className="text-3xl">{e?.id}</p> 
                    </div>
                );
                })}
            </div>
            
            <div className="z-20 h-full">
            </div> 
        </div>      
)}
export default LessonLevel; 