export const Colors = {
    bgColor: "#0B0C0F",
    baseColor: "#340472",
    baseBlue: "#1974C7",
    hpink: "#E2CFEA",
    
    baseBlack: " #002233",
    baseBlue1: "#2650F4",
    helpGreen: "#70E000",
    helpGray: "#EBEBEB"
}

    